<template>
  <div>
    <Card :title="title">
      <template #card-body>
        <div
          v-if="html == ''"
          class="
            py-3
            d-flex
            align-items-center
            justify-content-center
            flex-column
            thumb-container
          "
          slot="card-body"
        >
          <fa-icon
            icon="exclamation-circle"
            class="text-warning icon-style"
          ></fa-icon>
          <p class="text-muted">Nenhum {{ title.toLowerCase() }} selecionado</p>
        </div>
        <div v-else class="thumb-container py-3" slot="card-body">
          <iframe class="thumbnail" :srcdoc="html" />
        </div>
      </template>
      <template #card-footer>
        <hr />
        <div class="mb-3 d-flex justify-content-end">
          <b-button variant="success" @click="open_slider">Selecionar</b-button>
        </div>
      </template>
    </Card>
    <SweetModal
      ref="slider_modal"
      overlay-theme="dark"
      width="80%"
      hide-close-button
    >
      <template #title>
        <span class="text-muted">Selecione o {{ title }}</span>
      </template>
      <template v-if="templates.length > 0">
        <slider ref="slider" :options="options" @slide="slideIndex">
          <slideritem
            v-for="header in templates"
            :key="header.id"
            :style="{ width: '31.4%', 'margin-right': '20px' }"
            ><input
              type="radio"
              @change="check"
              :checked="header.id == value"
              :value="header.id"
              class="check-img"
            />
            <div class="d-flex flex-column align-items-end">
              <Card :class="[{ isActive: value == header.id }]">
                <div class="thumb-container py-3" slot="card-body">
                  <iframe class="thumbnail" :srcdoc="header.html" />
                </div>
              </Card>
            </div>
          </slideritem>
        </slider>
        <div class="d-flex mt-4 justify-content-center">
          <b-button
            @click="$refs.slider.$emit('slidePre')"
            pill
            class="mr-2"
            variant="success"
            ><fa-icon icon="arrow-left"
          /></b-button>
          <b-button
            @click="$refs.slider.$emit('slideNext')"
            pill
            class="mr-2"
            variant="success"
          >
            <fa-icon icon="arrow-right"
          /></b-button>
        </div>
      </template>
      <template v-else>
        <div
          class="
            py-3
            d-flex
            align-items-center
            justify-content-center
            flex-column
            thumb-container
          "
        >
          <fa-icon
            icon="exclamation-circle"
            class="text-warning icon-style"
          ></fa-icon>
          <p class="text-muted">Nenhum {{ title.toLowerCase() }} criado.</p>
        </div>
      </template>
      <template #button>
        <b-button class="mr-2" variant="info" @click="create_new"
          >Criar Novo {{ title }}</b-button
        >
        <b-button
          v-if="templates.length > 0"
          @click="open_preview"
          class="mr-2"
          variant="info"
          >Visualizar Selecionado</b-button
        >
        <b-button @click="close_slider" variant="info">Confirmar</b-button>
      </template>
    </SweetModal>
    <SweetModal
      ref="preview_modal"
      overlay-theme="dark"
      width="80%"
      hide-close-button
    >
      <template #title>
        <span class="text-muted">Pré Visualização</span>
      </template>
      <iframe
        :srcdoc="html"
        frameborder="0"
        style="
          width: 100%;
          height: 70vh;
          overflow-y: scroll;
          border: 1px solid lightgray;
        "
      ></iframe>
      <template #button>
        <b-button @click="close_preview" variant="info">Fechar</b-button>
      </template>
    </SweetModal>
    <SweetModal
      ref="quick_template_modal"
      overlay-theme="dark"
      width="90%"
      hide-close-button
    >
      <template #title>
        <b-row class="d-flex" align-h="around">
          <b-col>
            <span>
              Criação rápida de <strong>{{ title }}</strong>
            </span>
          </b-col>
          <b-col>
            <span>
              Para a aplicação <strong>{{ getAppId[0].description }}</strong>
            </span>
          </b-col>
          <b-col>
            <span>
              Tipo de Notificação
              <strong>{{ getNotificationType[0].description }}</strong>
            </span>
          </b-col>
        </b-row>
      </template>
      <b-row class="mb-4">
        <b-col class="d-flex align-items-baseline">
          <ListTags
            v-if="optionalVariables.length > 0"
            :variables="optionalVariables"
            :element="'optionalVariables'"
            :title="'Tags Opcionais'"
          />
        </b-col>
      </b-row>
      <b-row>
        
      </b-row>

      <MailEditor v-if="creating" ref="quick_template_editor" />
      <template #button>
        <b-button @click="save_template" variant="success">Salvar</b-button>
      </template>
    </SweetModal>

    <SweetModal
      ref="tagError"
      overlay-theme="dark"
      icon="warning"
      hide-close-button
    >
      <div class="mt-3" v-if="invalidTags.length > 0">
        <p class="lead">As seguintes tags são invalidas</p>
        <span v-for="(tag, idx) in invalidTags" :key="idx">
          {{ getTag(tag) }}
        </span>
        <p class="text-muted mt-3">
          Favor remover do corpo do email as tags mostradas acima
        </p>
      </div>
      <hr v-if="invalidTags.length > 0 && requiredTags.length > 0" />
      <div class="mt-3" v-if="requiredTags.length > 0">
        <p class="lead">As seguintes tags são obrigatórias</p>
        <span v-for="(tag, idx) in requiredTags" :key="idx"
          >{{ getTag(tag) }}
        </span>
        <p class="text-muted mt-3">
          Favor incluir no corpo do email as tags conforme mostradas acima
        </p>
      </div>
      <!-- <div class="mt-3" v-if="requiredTags.length > 0">
        <p class="lead">As seguintes tags são opcionais</p>
        <span v-for="(tag, idx) in optionalTags" :key="idx"
          >{{ getTag(tag) }}
        </span>
        <p class="text-muted mt-3">
          Favor incluir no corpo do email as tags conforme mostradas acima
        </p>
      </div> -->
      <b-button @click="$refs.tagError.close()" variant="warning">Ok</b-button>
    </SweetModal>
  </div>
</template>

<script>
import { slider, slideritem } from "vue-concise-slider";
export default {
  props: {
    title: {
      type: String,
    },
    templates: {
      type: Array,
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      html: "",
      creating: false,
      invalidTags: [],
      requiredTags: [],
      options: {
        itemAnimation: true,
        loopedSlides: 1,
        slidesToScroll: 1,
        thresholdDistance: 200,
        pagination: false,
        currentPage: 0,
      },
    };
  },
  components: {
    slider,
    slideritem,
    MailEditor: () => import("@/components/EmailEditor"),
    ListTags: () => import("./ListTags.vue"),
  },
  computed: {
    applicationId() {
      return this.$parent.selectedApplication;
    },
    notificationTypeId() {
      return this.$parent.selectedNotificationType;
    },
    getAppId() {
      const application = _.filter(
        this.$store.state.templates.unityApplications,
        (app) => {
          return app.value == this.applicationId;
        }
      );
      if (application.length > 0) {
        return application;
      } else {
        return [{ description: "Para todas as aplicações" }];
      }
    },
    getNotificationType() {
      return _.filter(this.$store.state.templates.notificationTypes, (type) => {
        return type.id == this.notificationTypeId;
      });
    },
    optionalVariables() {
      let variables = [];
      if (this.getNotificationType[0].variables) {
        variables = [
          ...variables,
          ...this.getNotificationType[0].variables.split(","),
        ];
      }
      if (this.getNotificationType[0].required_variables) {
        variables = [
          ...variables,
          ...this.getNotificationType[0].required_variables.split(","),
        ];
      }
      if (this.$store.state.templates.templatesVariables.length > 0) {
        let selectedType = "";

        if (this.title == "Cabeçalho") {
          selectedType = "header";
        }
        if (this.title == "Rodapé") {
          selectedType = "footer";
        }

        if (
          this.$store.state.templates.templatesVariables[0].type == selectedType
        ) {
          variables = [
            ...variables,
            ...this.$store.state.templates.templatesVariables[0].variables.split(
              ","
            ),
          ];
        }
      }
      return variables;
    },
  },
  methods: {
    getTag(tag) {
      return `{{${tag.replace(/ /g, "")}}}`;
    },
    async save_template() {
      let { design, html } =
        await this.$refs.quick_template_editor.save_design();

      const { required, invalid } = this.check_tag(
        html,
        this.optionalVariables
      );

      if (required.length > 0 || invalid.length > 0) {
        this.invalidTags = invalid;
        this.requiredTags = required;
        this.$refs.tagError.open();
      } else {
                
        const application_id =
          this.applicationId == "all" ? null : this.applicationId;
        const unity_id = this.$store.state.auth.currentUser.unity_id;
        const notification_type_id = this.notificationTypeId;
        const variables = this.optionalVariables.toString();
        let type = "";
        if (this.title == "Cabeçalho") {
          type = "header";
        }
        if (this.title == "Rodapé") {
          type = "footer";
        }

        const model = {
          application_id,
          unity_id,
          notification_type_id,
          type,
          design: JSON.stringify(design),
          html,
          variables,
          active: "1",
        };        
        await this.$store.dispatch("templates/save_template", model);
        this.creating = false;
        this.$refs.quick_template_modal.close();
      }
    },

    check(event) {
      console.log('changed')
      this.$emit("input", event.target.value);
      this.setHtml(event.target.value);
    },
    setHtml(id) {
      const getFilteredTemplate = _.filter(this.templates, (template) => {
        return template.id == id;
      });
      this.html = getFilteredTemplate[0].html;
    },
    open_slider() {
      this.$refs.slider_modal.open();
    },
    close_slider() {
      this.$refs.slider_modal.close();
    },
    open_preview() {
      this.$refs.preview_modal.open();
    },
    close_preview() {
      this.$refs.preview_modal.close();
    },
    slideIndex(idx) {
      this.options.currentPage = idx.currentPage;
    },
    create_new() {
      this.creating = true;
      this.$refs.quick_template_modal.open();
    },
  },
  mounted() {
    if (this.value !== "") {
      this.setHtml(this.value);
    }
  },
};
</script>

<style lang="scss" scoped>
.icon-style {
  font-size: 3rem;
  margin-bottom: 1rem;
}
.thumbnail {
  position: relative;
  -ms-zoom: 0.35;
  -moz-transform: scale(0.35);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.35);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.35);
  -webkit-transform-origin: 0 0;
  width: 285%;
  border: none;
  height: 65vh;
}
.thumb-container {
  height: 30vh;
}
.thumbnail-bg {
  position: relative;
  -ms-zoom: 0.35;
  -moz-transform: scale(0.35);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.35);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.35);
  -webkit-transform-origin: 0 0;
  width: 285%;
  border: none;
  height: 65vh;
}
.thumb-container-bg {
  align-self: center;
  width: 16vw;
  height: 30vh;
}
.isActive {
  border: 1px solid #007bff;
}
.check-img {
  width: 25px;
  height: 25px;
  margin-right: 10px !important;
}
.thumbnail {
  position: relative;
  -ms-zoom: 0.35;
  -moz-transform: scale(0.35);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.35);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.35);
  -webkit-transform-origin: 0 0;
  width: 285%;
  border: none;
  height: 65vh;
}
</style>