import { render, staticRenderFns } from "./ModelConfig.vue?vue&type=template&id=e2649462&scoped=true&"
import script from "./ModelConfig.vue?vue&type=script&lang=js&"
export * from "./ModelConfig.vue?vue&type=script&lang=js&"
import style0 from "./ModelConfig.vue?vue&type=style&index=0&id=e2649462&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2649462",
  null
  
)

export default component.exports